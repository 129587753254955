import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition";

// Connects to data-controller="dialog"
export default class extends Controller {
  static targets = ["backdrop", "content"];

  opened = false;

  async toggle() {
    if (this.opened) {
      await this.hide();
    } else {
      await this.show();
    }
  }

  async show() {
    this.element.classList.remove("hidden");
    this.element.ariaModal = "true";
    await Promise.all([enter(this.backdropTarget), enter(this.contentTarget)]);
    this.opened = true;
  }

  async hide() {
    await Promise.all([leave(this.backdropTarget), leave(this.contentTarget)]);
    this.element.classList.add("hidden");
    this.element.ariaModal = "false";
    document.getElementById("focus-out").focus(); // TODO to trigger and focus_controller
    this.opened = false;
  }
}
