import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition";
import { noop } from "../helpers/noop";

// Connects to data-controller="toast"
export default class extends Controller {
  static values = {
    timeout: { type: Number, default: 3000 },
  };

  timeout = null;

  connect() {
    this.open().then(noop);
  }

  disconnect() {
    if (this.element) {
      this.dismiss();
    } else {
      this.resetTimeout();
    }
  }

  async open() {
    await enter(this.element);
    this.timeout = window.setTimeout(() => this.dismiss(), this.timeoutValue);
  }

  async dismiss() {
    this.resetTimeout();
    await leave(this.element);
    this.element.remove();
  }

  resetTimeout() {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
      this.timeout = null;
    }
  }
}
