import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="trigger"
export default class extends Controller {
  static outlets = ["bottom-sheet"];

  toggle() {
    if (this.hasBottomSheetOutlet) {
      this.bottomSheetOutlet.toggle();
    }
  }
}