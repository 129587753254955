import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition";

// Connects to data-controller="bottom-sheet"
export default class extends Controller {
  static targets = [
    "backdrop",
    "content",
    "handle",
  ];

  static values = {
    open: Boolean,
    mode: String,
  };

  contentY = 0
  isDragging = false;
  isOpen = false;
  startHeight = 0;
  startY = 0;
  threshold = 0

  async connect() {
    this.contentY = 0;
    this.isDragging = false
    this.isOpen = false;
    this.startHeight = 0;
    this.startY = 0;
    this.threshold = 0

    if (this.openValue) {
      await this.open()
    }
  }

  async open() {
    this.isOpen = true;

    window.document.body.style.overflowY = "hidden";
    this.resetHeight()

    await Promise.all([
      enter(this.backdropTarget),
      enter(this.contentTarget),
      enter(this.element),
    ]);

    this.handleTarget.addEventListener("mousedown", this.dragStart.bind(this));
    this.handleTarget.addEventListener("touchstart", this.dragStart.bind(this));
    this.handleTarget.addEventListener("mousemove", this.dragging.bind(this));
    this.handleTarget.addEventListener("touchmove", this.dragging.bind(this));
    this.handleTarget.addEventListener("mouseup", this.dragStop.bind(this));
    this.handleTarget.addEventListener("touchend", this.dragStop.bind(this));
  }

  async close() {
    this.isOpen = false;

    window.document.body.style.overflowY = null;
    this.contentTarget.style.transform = null;

    await Promise.all([
      leave(this.backdropTarget),
      leave(this.contentTarget),
      leave(this.element),
    ]);

    this.updateHeight(0);

    this.handleTarget.removeEventListener("mousedown", this.dragStart.bind(this));
    this.handleTarget.removeEventListener("touchstart", this.dragStart.bind(this));
    this.handleTarget.removeEventListener("mousemove", this.dragging.bind(this));
    this.handleTarget.removeEventListener("touchmove", this.dragging.bind(this));
    this.handleTarget.removeEventListener("mouseup", this.dragStop.bind(this));
    this.handleTarget.removeEventListener("touchend", this.dragStop.bind(this));

    document.getElementById("focus-out")?.focus();
  }

  async toggle() {
    if (this.isOpen) {
      await this.close();
    } else {
      await this.open();
    }
  }

  resetHeight() {
    if (this.modeValue === "full") {
      this.startHeight = window.document.documentElement.clientHeight - 60;
    } else if (this.modeValue === "half") {
      this.startHeight = window.document.documentElement.clientHeight / 2;
    }

    this.updateHeight(this.startHeight);
  }

  updateHeight(height) {
    this.contentTarget.style.height = `${height}px`;
  }

  dragStart(event) {
    this.isDragging = true;
    this.contentTarget.style.transitionDuration = "unset";

    this.startY = event.clientY || event.touches[0].clientY;
    this.contentY = parseInt(window.getComputedStyle(this.contentTarget).transform.split(",")[5]) || 0;
  }

  dragging(event) {
    if (!this.isDragging) return;

    const clientY = event.clientY || event.touches[0].clientY;
    const deltaY = clientY - this.startY;
    const newY = this.contentY + deltaY;
    if (newY > 0) {
      this.contentTarget.style.transform = `translateY(${newY}px)`;
    }
  }

  async dragStop() {
    if (!this.isDragging) return;

    this.isDragging = false;
    this.contentTarget.style.transitionDuration = null;

    const finalY = parseInt(this.contentTarget.style.transform.replace(/[^0-9.\-]/g, ""));
    if (finalY > (this.startHeight / 3)) {
      await this.close();
    } else {
      this.contentTarget.style.transform = "translateY(0)";
    }
  }
}